<template>
    <div class="applicationDistribution">
        <jy-dialog :title="title" :visible.sync="dialogVisible" type="table">
            <jy-query ref="queryForm">
                <template>
                    <el-row>
                        <el-button type="primary" @click="add">添加机构</el-button>
                        <el-button type="primary" @click="batchDelete">批量删除</el-button>
                    </el-row>
                </template>
            </jy-query>
            <jy-table :data="lists" @selection-change="batchchange">
                <jy-table-column type="index" width="60" label="序号"></jy-table-column>
                <jy-table-column type="selection" width="70"></jy-table-column>
                <jy-table-column label="机构名称" prop="orgName" min-width="100"></jy-table-column>
                <jy-table-column label="上级机构" prop="parentName" min-width="100"></jy-table-column>
                <jy-table-column label="创建日期" prop="createTime" min-width="100"></jy-table-column>
                <jy-operate :width="operateWidth" :list="operateList"></jy-operate>
            </jy-table>
            <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
            </jy-pagination>
        </jy-dialog>
        <add-dialog ref="dialog" @updateList="oncheck"></add-dialog>
    </div>
</template>
<script>
import adddialog from '@/components/application/addInstitutions'
export default {
    data() {
        return {
            // 对话框类型
            appId: '',
            title: '机构管理',

            dialogVisible: false,

            lists: [{}],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
            //  操作
            operateList: [],
            operateWidth: '200px',
            //批量操作
            batchDeletearr: [],
        }
    },
    components: {
        'add-dialog': adddialog,
    },
    created() {
        this.setOperateList()
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                console.log(newview)
            }
        },
    },
    methods: {
        setOperateList() {
            let l = [
                {
                    name: '编辑',
                    icon: 'el-icon-edit-outline',
                    fun: this.handleEdit,
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                },
            ]
            this.operateList = l
        },
        // 打开对话框
        init(option) {
            this.dialogVisible = true
            this.$nextTick(() => {
                this.appId = option.appId
                this.getlist()
            })
        },
        getlist() {
            let url = '/app/info/queryOrgManage'
            let option = {
                appId: this.appId,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            this.$http.post(url, option).then(res => {
                this.lists = res.detail.list
                this.total = res.detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },
        // 新增
        add() {
            this.$refs.dialog.init({ appId: this.appId })
            console.log('新增')
        },
        // 修改
        handleEdit(row) {
            this.$refs.dialog.init({ appId: this.appId, ...row }, 'update')
            console.log('编辑')
        },
        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该机构?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let params = []
                    params.push(row.orgId)
                    this.deleteRecord(params)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
            //   console.log(row)
        },
        //批量删除
        batchchange(val) {
            let arr = []
            val.forEach(item => {
                arr.push(item.orgId)
            })
            this.batchDeletearr = arr
        },
        batchDelete() {
            if (this.batchDeletearr.length == 0) {
                this.$message({
                    message: '请先选择删除项',
                    type: 'warning',
                })
                return false
            }
            this.$confirm('是否删除选中机构?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.deleteRecord(this.batchDeletearr)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        deleteRecord(arr) {
            let url = 'app/info/delOrgManage'
            let option = {
                appId: this.appId,
                orgIds: arr,
            }
            this.$http.post(url, option).then(res => {
                this.$message({
                    message: res.msg,
                    type: 'success',
                })
                this.oncheck()
            })
        },
    },
}
</script>
<style lang="scss" scoped="applicationDistribution">
</style>

