<template>
    <div class="adduser">
        <jy-dialog :title="title" :visible.sync="dialogVisible">
            <div>
                <el-form :model="diaform" ref="diaform" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="机构名称:" prop="orgName">
                        <el-input v-model="diaform.orgName"></el-input>
                    </el-form-item>
                    <el-form-item label="上级机构:" prop="parentId">
                        <el-cascader ref="cascaderorg" :key="isResouceShow" :options="orgOptions" v-model="diaform.parentId" :props="orgProps" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label="机构类型:" prop="orgTypeId" v-if="isshow">
                        <el-select v-model="diaform.orgTypeId" placeholder="请选择">
                            <el-option v-for="item in typeOptions" :key="item.orgTypeId" :label="item.orgType" :value="item.orgTypeId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="地址:" prop="address" v-if="this.appId != 'pc_07'&&this.appId != 'pc_05'">
                        <el-input v-model="diaform.address"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人:" prop="contactPerson" v-if="this.appId != 'pc_07'&&this.appId != 'pc_05'">
                        <el-input v-model="diaform.contactPerson"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话:" prop="mobile" v-if="this.appId != 'pc_07'&&this.appId != 'pc_05'">
                        <el-input v-model="diaform.mobile"></el-input>
                    </el-form-item>
                    <el-form-item label="备注:" prop="remark" :label-width="formLabelWidth" v-if="this.appId != 'pc_05'">
                        <el-input v-model="diaform.remark" type="textarea" :rows="2"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isshow:true,
            // 对话框类型
            appId: '',
            type: '',
            title: '',
            isResouceShow: 0,

            diaform: {
                orgName: '',
                parentId: [],
                orgTypeId:'',
                address: '',
                contactPerson: '',
                mobile: '',
                remark: '',
                orgId: '',
            },
            dialogVisible: false,
            formLabelWidth: '100px',

            rules: {
                orgName: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur',
                    },
                ],
                parentId: [
                    {
                        required: true,
                        message: '请选择机构',
                        trigger: 'change',
                    },
                ],
                  orgTypeId: [
                    {
                        required: true,
                        message: '请选择机构类型',
                        trigger: 'change',
                    },
                ],
            },
            typeOptions:[],
            orgOptions: [],
            orgProps: {
                checkStrictly: true,
                value: 'id',
                label: 'text',
            },
        }
    },
    components: {},
    created() {},
    activated() {},
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('diaform')
            }
        },
    },
    methods: {
        // 打开对话框
        init(option, type) {
            this.type = type || 'add'
            this.dialogVisible = true
            this.appId = option.appId
            console.log(this.appId)
            this.getOrgOption()
            if(this.appId == 'pc_09' ||this.appId == 'pc_07'||this.appId == 'pc_03'){
                this.getTypeOption()
                this.isshow = true
            }else{
                this.isshow = false
            }
            if (this.type == 'update') {
                this.title = '编辑'
                this.$nextTick(() => {
                    let op = JSON.parse(JSON.stringify(option))
                    this.diaform.orgName = op.orgName
                    if (op.frontDisplay) {
                        let display = op.frontDisplay.split(',')
                        display.forEach((item, k) => {
                            display[k] = item
                        })
                        this.diaform.parentId = display
                    } else {
                        this.diaform.parentId = op.parentId
                    }
                    console.log(this.diaform.parentId)
                    this.diaform.address = op.address
                    this.diaform.contactPerson = op.contactPerson
                    this.diaform.mobile = op.mobile
                    this.diaform.remark = op.remark
                    this.diaform.orgId = op.orgId
                    this.diaform.orgTypeId = op.orgTypeId
                })
            } else {
                this.title = '新增'
            }
        },
        // 重置
        resetForm(formName) {
            this.$nextTick(() => {
                this.$refs[formName].resetFields()
                 this.isshow = true
                this.appId = ''
                this.diaform.orgId = ''
            })
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.diaform.validate(valid => {
                if (valid) {
                    let url = ''
                    if (this.type == 'add') {
                        url = '/app/info/addOrgManage'
                    } else {
                        url = '/app/info/modifyOrgManage'
                    }
                    let option = JSON.parse(JSON.stringify(this.diaform))
                    option.appId = this.appId
                    if (
                        typeof option.parentId == 'object' &&
                        option.parentId.length
                    ) {
                        option.frontDisplay = option.parentId.toString()
                        option.parentId =
                            option.parentId[option.parentId.length - 1]
                    } else if (
                        (typeof option.parentId == 'object' &&
                            !option.parentId.length) ||
                        (typeof option.parentId != 'object' && !option.parentId)
                    ) {
                        option.parentId = '0'
                    }
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        },
        getOrgOption() {
            let url = '/sys/userApp/queryOrgDistribution'
            let option = {
                appId: this.appId,
                type: '1',
            }
            this.$http.post(url, option).then(res => {
                console.log(res)
                ++this.isResouceShow
                this.orgOptions = res.detail
            })
        },
        getTypeOption() {
            let url = '/app/info/queryOrgType'
            let option = {
                appId: this.appId,
            }
            this.$http.post(url, option).then(res => {
                console.log(res)
                this.typeOptions = res.detail
            })
        },
    },
}
</script>
<style lang="scss" scoped="adduser">
</style>
<style lang="scss">
</style>

