<template>
    <div class="userlist">
        <jy-dialog :title="title" :visible.sync="dialogVisible" type="table">
            <jy-query :model="formInline" ref="queryForm">
                <jy-query-item label="登录账号" prop="userName">
                    <el-input v-model="formInline.userName" placeholder="输入账号"></el-input>
                </jy-query-item>
                <jy-query-item label="用户姓名" prop="nickName">
                    <el-input v-model="formInline.nickName" placeholder="输入名称"></el-input>
                </jy-query-item>
                <jy-query-item label="联系方式" prop="mobile">
                    <el-input v-model="formInline.mobile" placeholder="输入联系方式"></el-input>
                </jy-query-item>
                <template slot="rightCol">
                    <el-button type="primary" @click="oncheck">查询</el-button>
                    <el-button type="primary" @click="resetForm('queryForm')">重置</el-button>
                </template>
            </jy-query>
            <jy-table :data="lists">
                <jy-table-column type="index" width="60" label="序号"></jy-table-column>
                <jy-table-column label="登录账号" prop="userName" min-width="100"></jy-table-column>
                <jy-table-column label="用户姓名" prop="nickName" min-width="100"></jy-table-column>
                <jy-table-column label="性别" min-width="60">
                    <template slot-scope="scope">
                        {{scope.row.gender |change }}
                    </template>
                </jy-table-column>
                <jy-table-column label="联系方式" prop="mobile" min-width="100"></jy-table-column>
                <jy-table-column label="所属机构" prop="orgName" min-width="100"></jy-table-column>
                <jy-table-column label="注册渠道" min-width="100">
                    <template slot-scope="scope">
                        {{scope.row.fromType |changeType }}
                    </template>
                </jy-table-column>
                <jy-table-column label="注册时间" prop="createTime" min-width="150"></jy-table-column>
            </jy-table>
            <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
            </jy-pagination>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 对话框类型
            appId: '',
            title: '用户列表',

            formInline: {
                userName: '',
                nickName: '',
                mobile: '',
            },

            dialogVisible: false,

            lists: [],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
        }
    },
    components: {},
    created() {},
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('queryForm')
            }
        },
    },
    filters: {
        change(val) {
            if (val == '0') {
                return '女'
            } else {
                return '男'
            }
        },
        changeType(val) {
            if (val == '1') {
                return '小程序'
            } else {
                return '后台'
            }
        },
    },
    methods: {
        // 打开对话框
        init(option) {
            this.dialogVisible = true
            this.appId = option.appId
            this.getlist()
        },
        getlist() {
            let url = '/app/info/memberList'
            let option = {
                appId: this.appId,
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            this.$http.post(url, option).then(res => {
                this.lists = res.detail.list
                this.total = res.detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$nextTick(() => {
                this.$refs[formName].resetFields()
                if (this.dialogVisible) {
                    this.oncheck()
                }
            })
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },
    },
}
</script>
<style lang="scss" scoped="userlist">
</style>

