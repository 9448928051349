<template>
    <div class="applicationDistribution">
        <jy-dialog :title="title" :visible.sync="dialogVisible" type="table">
            <jy-query :model="formInline" ref="queryForm">
                <jy-query-item label="登录账号" prop="userName">
                    <el-input v-model="formInline.userName" placeholder="输入登录账号"></el-input>
                </jy-query-item>
                <template slot="rightCol">
                    <el-button type="primary" @click="oncheck">查询</el-button>
                    <el-button type="primary" @click="resetForm('queryForm')">重置</el-button>
                    <el-row>
                        <el-button type="primary" @click="add">添加用户</el-button>
                        <el-button type="primary" @click="batchDelete">批量删除</el-button>
                    </el-row>
                </template>
            </jy-query>
            <jy-table :data="lists" @selection-change="batchchange">
                <jy-table-column type="index" width="60" label="序号"></jy-table-column>
                <jy-table-column type="selection" width="70"></jy-table-column>
                <jy-table-column label="登录账号" prop="userName" min-width="100"></jy-table-column>
                <jy-table-column label="用户名称" prop="realName" min-width="100"></jy-table-column>
                <jy-table-column label="所属机构" prop="orgName" min-width="100"></jy-table-column>
                <jy-table-column label="所属角色" prop="roleName" min-width="100"></jy-table-column>
                <jy-table-column label="账号状态" min-width="100">
                    <template slot-scope="scope">
                        {{scope.row.status | change}}
                    </template>
                </jy-table-column>
                <jy-table-column label="创建日期" prop="createTime" min-width="100"></jy-table-column>
                <jy-operate :width="operateWidth" :list="operateList"></jy-operate>
            </jy-table>
            <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
            </jy-pagination>
        </jy-dialog>
        <add-dialog ref="dialog" @updateList="oncheck"></add-dialog>
    </div>
</template>
<script>
import adddialog from '@/components/application/adduser'
export default {
    data() {
        return {
            // 对话框类型
            appId: '',
            title: '用户管理',

            formInline: {
                userName: '',
            },

            dialogVisible: false,

            lists: [
                {
                    bianhao: 'PC_001',
                    mingcheng: 'PC_001',
                    dizhi: 'PC_001',
                    leixing: 'PC_001',
                    shuliang: 'PC_001',
                    chuangjiangzhe: 'PC_001',
                    time: 'PC_001',
                },
            ],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
            //  操作
            operateList: [],
            operateWidth: '200px',
            //批量操作
            batchDeletearr: [],
        }
    },
    filters: {
        change(val) {
            if (val == '0') {
                return '禁用'
            } else {
                return '正常'
            }
        },
    },
    components: {
        'add-dialog': adddialog,
    },
    created() {
        this.setOperateList()
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('queryForm')
            }
        },
    },
    methods: {
        setOperateList() {
            let l = [
                {
                    name: '编辑',
                    icon: 'el-icon-edit-outline',
                    fun: this.handleEdit,
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                },
            ]
            this.operateList = l
        },
        // 打开对话框
        init(option) {
            this.dialogVisible = true
            this.$nextTick(() => {
                this.appId = option.appId
                this.getlist()
            })
        },
        getlist() {
            let url = '/app/info/queryMemberManage'
            let option = {
                ...this.formInline,
                appId: this.appId,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            this.$http.post(url, option).then(res => {
                this.lists = res.detail.list
                this.total = res.detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$nextTick(() => {
                this.$refs[formName].resetFields()
                if (this.dialogVisible) {
                    this.oncheck()
                }
            })
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },
        // 新增
        add() {
            this.$refs.dialog.init({ appId: this.appId })
            console.log('新增')
        },
        // 修改
        handleEdit(row) {
            this.$refs.dialog.init({ appId: this.appId, ...row }, 'update')
            console.log('编辑')
        },
        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该用户?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let params = []
                    params.push(row.userId)
                    this.deleteRecord(params)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
            //   console.log(row)
        },
        //批量删除
        batchchange(val) {
            let arr = []
            val.forEach(item => {
                arr.push(item.userId)
            })
            this.batchDeletearr = arr
        },
        batchDelete() {
            if (this.batchDeletearr.length == 0) {
                this.$message({
                    message: '请先选择删除项',
                    type: 'warning',
                })
                return false
            }
            this.$confirm('是否删除选中用户?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.deleteRecord(this.batchDeletearr)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        deleteRecord(arr) {
            let url = 'app/info/delMemberManage'
            let option = {
                appId: this.appId,
                userIds: arr,
            }
            this.$http.post(url, option).then(res => {
                this.$message({
                    message: res.msg,
                    type: 'success',
                })
                this.oncheck()
            })
        },
    },
}
</script>
<style lang="scss" scoped="applicationDistribution">
</style>

