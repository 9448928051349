<template>
    <div class="adduser">
        <jy-dialog :title="title" :visible.sync="dialogVisible">
            <div>
                <el-form :model="diaform" ref="diaform" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="登录账号:" prop="userName">
                        <el-input v-model="diaform.userName"></el-input>
                    </el-form-item>
                    <el-form-item label="用户名称:" prop="realName">
                        <el-input v-model="diaform.realName"></el-input>
                    </el-form-item>
                    <div v-if="type=='add'">
                        <el-form-item label="登录密码:" prop="password">
                            <el-input v-model="diaform.password" show-password></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码:" prop="againpassword">
                            <el-input v-model="diaform.againpassword" show-password></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="所属机构:" prop="orgId">
                        <el-cascader ref="cascaderorg" :key="isResouceShow" :options="orgOptions" v-model="diaform.orgId" :props="orgProps" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label="所属角色:" prop="roleIds">
                        <el-select v-model="diaform.roleIds" placeholder="请选择" multiple>
                            <el-option v-for="item in roleOptions" :key="item.roleId" :label="item.roleName" :value="item.roleId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="用户工种:" prop="userTypeId" v-if="ishavaType">
                        <el-select v-model="diaform.userTypeId" placeholder="请选择">
                            <el-option v-for="item in userOptions" :key="item.userTypeId" :label="item.userTypeName" :value="item.userTypeId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="类型" prop="effectiveType">
                        <el-select v-model="diaform.effectiveType" placeholder="请选择">
                            <el-option label="短期有效" value="0"></el-option>
                            <el-option label="长期有效" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <div v-if="diaform.effectiveType == '0'">
                        <el-form-item label="有效期" prop="time">
                            <el-date-picker v-model="diaform.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </div>
                    <el-form-item label="账号状态:" prop="status">
                        <el-radio-group v-model="diaform.status">
                            <el-radio label="1">启用</el-radio>
                            <el-radio label="0">禁用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isupdate: false,
            // 对话框类型
            appId: '',
            type: '',
            title: '',
            ishavaType: true,

            diaform: {
                userName: '',
                realName: '',
                password: '',
                againpassword: '',
                orgId: [],
                roleIds: [],
                userTypeId: '',
                effectiveType: '',
                time: [],
                status: '',
                userId: '',
            },
            isResouceShow: 0,

            dialogVisible: false,
            formLabelWidth: '100px',

            rules: {
                userName: [
                    {
                        required: true,
                        message: '请输入账号',
                        trigger: 'blur',
                    },
                ],
                realName: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '请输入密码',
                        trigger: 'blur',
                    },
                ],
                againpassword: [
                    {
                        required: true,
                        message: '请输入确认密码',
                        trigger: 'blur',
                    },
                ],
                orgId: [
                    {
                        required: true,
                        message: '请选择机构',
                        trigger: 'change',
                    },
                ],
                roleIds: [
                    {
                        required: true,
                        message: '请选择角色',
                        trigger: 'change',
                    },
                ],
                userTypeId: [
                    {
                        required: true,
                        message: '请选择工种',
                        trigger: 'change',
                    },
                ],
                effectiveType: [
                    {
                        required: true,
                        message: '请选择类型',
                        trigger: 'change',
                    },
                ],
                time: [
                    {
                        required: true,
                        message: '请选择日期',
                        trigger: 'change',
                    },
                ],
                status: [
                    {
                        required: true,
                        message: '请选择状态',
                        trigger: 'change',
                    },
                ],
            },

            orgOptions: [],
            roleOptions: [],
            userOptions: [],

            orgProps: {
                checkStrictly: true,
                value: 'id',
                label: 'text',
            },
        }
    },
    components: {},
    created() {},
    activated() {},
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.ishavaType = true
                this.resetForm('diaform')
                this.$nextTick(()=>{
                this.diaform.time = []
                })
                this.appId = ''
                this.diaform.userId = ''
                this.userOptions = []
                this.isResouceShow = this.isResouceShow + 1
            }
        },
        'diaform.orgId'(val) {
            console.log(val)
            if (!this.isupdate) {
                this.diaform.roleIds = []
            }

            this.roleOptions = []
            if (typeof this.diaform.orgId == 'object' && val.length) {
                console.log(val)
                this.getRolesOption()
            } else if (typeof this.diaform.orgId != 'object' && val) {
                console.log(val)
                this.getRolesOption()
            }
        },
        'diaform.roleIds'(val) {
            if (val.length) {
                let isHave = false
                val.forEach(item => {
                    this.roleOptions.forEach(one => {
                        if (one.roleId == item && one.isService == 1) {
                            isHave = true
                        }
                    })
                })
                if (isHave) {
                    this.ishavaType = true
                    this.diaform.userTypeId = ''
                    this.userOptions = []
                    this.getUserOptions()
                } else {
                    this.ishavaType = false
                    this.diaform.userTypeId = ''
                }
            } else {
                this.ishavaType = false
                this.diaform.userTypeId = ''
            }
        },
    },
    methods: {
        // 打开对话框
        init(option, type) {
            this.type = type || 'add'
            this.dialogVisible = true
            this.appId = option.appId
            this.getOrgOption()
            if (this.type == 'update') {
                this.title = '编辑'
                this.isupdate = true
                this.$nextTick(() => {
                    let op = JSON.parse(JSON.stringify(option))
                    this.diaform.userId = op.userId
                    this.diaform.userName = op.userName
                    this.diaform.realName = op.realName
                    let ro = op.roleId.split(',')
                    ro.forEach((item, k) => {
                        ro[k] = item
                    })
                    this.diaform.roleIds = ro
                    this.$nextTick(() => {
                        this.diaform.userTypeId = op.userTypeId
                        if (this.diaform.userTypeId) {
                            this.ishavaType = true
                            this.getUserOptions()
                        }
                        this.isupdate = false
                    })
                    // this.$set(this.diaform, 'roleIds', ro)
                    console.log(this.diaform.roleIds)
                    this.diaform.effectiveType = op.effectiveType
                    if (op.enableTime) {
                        this.diaform.time = [op.enableTime, op.disableTime]
                    } else {
                        this.diaform.time = []
                    }
                    this.diaform.status = op.status

                    if (op.frontDisplay) {
                        let display = op.frontDisplay.split(',')
                        display.forEach((item, k) => {
                            display[k] = item
                        })
                        this.diaform.orgId = display
                    } else {
                        this.diaform.orgId = op.orgId
                    }
                    console.log(this.diaform.orgId)
                })
            } else {
                this.title = '新增'
                this.$nextTick(() => {
                    this.ishavaType = false
                })
            }
        },
        // 重置
        resetForm(formName) {
            this.$nextTick(() => {
                this.$refs[formName].resetFields()
            })
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.diaform.validate(valid => {
                if (valid) {
                    if (this.diaform.password != this.diaform.againpassword) {
                        this.$message({
                            message: '登录密码与确认密码不一致',
                            type: 'warning',
                        })
                        return false
                    }
                    let url = ''
                    if (this.type == 'add') {
                        url = '/app/info/addMemberManage'
                    } else {
                        url = '/app/info/modifyMemberManage'
                    }
                    let option = JSON.parse(JSON.stringify(this.diaform))
                    option.appId = this.appId
                    if (option.time.length && option.effectiveType == '0') {
                        option.enableTime = option.time[0]
                        option.disableTime = option.time[1]
                    } else {
                        option.enableTime = ''
                        option.disableTime = ''
                    }
                    if (typeof option.orgId == 'object') {
                        option.frontDisplay = option.orgId.toString()
                        option.orgId = option.orgId[option.orgId.length - 1]
                    }

                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        },
        getOrgOption() {
            let url = '/sys/userApp/queryOrgDistribution'
            let option = {
                appId: this.appId,
            }
            this.$http.post(url, option).then(res => {
                console.log(res)
                this.isResouceShow = this.isResouceShow + 1
                let filter = function(item) {
                    item.forEach((one, k) => {
                        if (one.children && one.children.length == 0) {
                            item[k].children = null
                        }
                        if (one.children && one.children.length > 0) {
                            filter(one.children)
                        }
                    })
                }
                filter(res.detail)
                this.orgOptions = res.detail
            })
        },
        getRolesOption() {
            let url = '/sys/userApp/queryRoleDistribution'
            let org = ''
            if (typeof this.diaform.orgId == 'object') {
                org = this.diaform.orgId[this.diaform.orgId.length - 1]
            } else {
                org = this.diaform.orgId
            }
            let option = {
                appId: this.appId,
                orgId: org,
            }
            this.$http.post(url, option).then(res => {
                console.log(res)
                this.roleOptions = res.detail
            })
        },
        getUserOptions() {
            let url = '/app/info/queryUserType'
            let option = {
                appId: this.appId,
                type: '1',
            }
            this.$http.post(url, option).then(res => {
                this.userOptions = res.detail
            })
        },
    },
}
</script>
<style lang="scss" scoped="adduser">
</style>
<style lang="scss">
</style>

