<template>
    <div class="addapplication">
        <jy-dialog :title="title" :visible.sync="dialogVisible">
            <div>
                <el-form :model="diaform" ref="diaform" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="应用编号:" prop="appId">
                        <el-input v-model="diaform.appId" :disabled="type=='update'"></el-input>
                    </el-form-item>
                    <el-form-item label="应用名称:" prop="cName">
                        <el-input v-model="diaform.cName"></el-input>
                    </el-form-item>
                    <el-form-item label="应用地址:" prop="appAddr">
                        <el-input v-model="diaform.appAddr"></el-input>
                    </el-form-item>
                    <el-form-item label="接口地址:" prop="addr">
                        <el-input v-model="diaform.addr"></el-input>
                    </el-form-item>
                    <el-form-item label="应用类型:" prop="type">
                        <el-select v-model="diaform.type" placeholder="请选择">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="应用图标:" prop="logoImg">
                        <el-upload class="avatar-uploader" action="/supportCenter/oss/upload" :headers="headers" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                            <img v-if="diaform.logoImg" :src="diaform.logoImg" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="备注:" prop="remark" :label-width="formLabelWidth">
                        <el-input v-model="diaform.remark" type="textarea" :rows="2"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 对话框类型
            type: '',
            title: '',

            diaform: {
                appId: '',
                cName: '',
                appAddr: '',
                addr: '',
                type: '',
                logoImg: '',
                remark: '',
            },
            dialogVisible: false,
            formLabelWidth: '100px',
            options: [
                {
                    value: '0',
                    label: 'PC应用',
                },
                {
                    value: '1',
                    label: '移动应用',
                },
            ],

            rules: {
                appId: [
                    {
                        required: true,
                        message: '请输入应用编号',
                        trigger: 'blur',
                    },
                ],
                cName: [
                    {
                        required: true,
                        message: '请输入应用名称',
                        trigger: 'blur',
                    },
                ],
                // appAddr: [
                //     {
                //         required: true,
                //         message: '请输入应用地址',
                //         trigger: 'blur',
                //     },
                // ],
                // addr: [
                //     {
                //         required: true,
                //         message: '请输入接口地址',
                //         trigger: 'blur',
                //     },
                // ],
                type: [
                    {
                        required: true,
                        message: '请选择应用类型',
                        trigger: 'change',
                    },
                ],
            },
        }
    },
    components: {},
    computed: {
        headers() {
            let token = this.$util.getStor('token')
            return {
                token: token,
            }
        },
    },
    created() {},
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('diaform')
            }
        },
    },
    methods: {
        // 打开对话框
        init(option, type) {
            this.type = type || 'add'
            this.dialogVisible = true
            if (this.type == 'update') {
                this.title = '编辑'
                this.$nextTick(() => {
                    this.diaform.appId = option.appId
                    this.diaform.cName = option.cName
                    this.diaform.appAddr = option.appAddr
                    this.diaform.addr = option.addr
                    this.diaform.type = option.type
                    this.diaform.logoImg = option.logoImg
                    this.diaform.remark = option.remark
                })
            } else {
                this.title = '新增'
            }
        },
        // 重置
        resetForm(formName) {
            this.$nextTick(() => {
                this.$refs[formName].resetFields()
            })
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.diaform.validate(valid => {
                if (valid) {
                    let url = ''
                    if (this.type == 'add') {
                        url = '/app/info/save'
                    } else {
                        url = '/app/info/modify'
                    }
                    let option = this.diaform
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        },
        //图片
        handleAvatarSuccess(res, file) {
            console.log(file)
            if(file.response.code=='0'){
                this.diaform.logoImg = res.msg
            }else{
                this.$message({
                    message: file.response.msg,
                    type: 'error'
                });
            }
        },
        beforeAvatarUpload(file) {
            console.log(file)
            // const isJPG = file.type === 'image/jpeg'
            // const isLt2M = file.size / 1024 / 1024 < 2

            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG 格式!')
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 2MB!')
            // }
            // return isJPG && isLt2M
        },
    },
}
</script>
<style lang="scss" scoped="addapplication">
</style>
<style lang="scss">
.addapplication {
    .jy-dialog {
        .dialog_form {
            .avatar-uploader .el-upload {
                border: 1px dashed #d9d9d9;
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                width: auto;
                height: auto;
            }
            .avatar-uploader .el-upload:hover {
                border-color: #409eff;
            }
            .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 178px;
                height: 178px;
                line-height: 178px;
                text-align: center;
            }
            .avatar {
                width: 178px;
                height: 178px;
                display: block;
            }
        }
    }
}
</style>

