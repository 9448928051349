<template>
    <div class="adduser">
        <jy-dialog :title="title" :visible.sync="dialogVisible">
            <div>
                <el-form :model="diaform" ref="diaform" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="角色名称:" prop="roleName">
                        <el-input v-model="diaform.roleName"></el-input>
                    </el-form-item>
                    <el-form-item label="所属机构:" prop="orgId">
                        <el-cascader ref="cascaderorg" :key="isResouceShow" :options="orgOptions" v-model="diaform.orgId" :props="orgProps"></el-cascader>
                    </el-form-item>
                    <el-form-item label="角色类型:" prop="roleTypeId" v-if="isShow">
                        <el-select v-model="diaform.roleTypeId" placeholder="请选择">
                            <el-option v-for="item in roleTypeOptions" :key="item.roleTypeId" :label="item.roleTypeName" :value="item.roleTypeId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="角色说明:" prop="roleExplain">
                        <el-input v-model="diaform.roleExplain"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="备注:" prop="remark" :label-width="formLabelWidth">
                        <el-input v-model="diaform.remark" type="textarea" :rows="2"></el-input>
                    </el-form-item> -->
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isShow: true,
            // 对话框类型
            appId: '',
            type: '',
            title: '',
            isResouceShow: 0,

            diaform: {
                roleName: '',
                orgId: [],
                roleTypeId: '',
                roleExplain: '',
                // remark: '',
                roleId: '',
            },
            dialogVisible: false,
            formLabelWidth: '100px',

            rules: {
                roleName: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur',
                    },
                ],
                orgId: [
                    {
                        required: true,
                        message: '请选择机构',
                        trigger: 'change',
                    },
                ],
                roleTypeId: [
                    {
                        required: true,
                        message: '请选择类型',
                        trigger: 'change',
                    },
                ],
            },
            orgOptions: [],
            orgProps: {
                checkStrictly: true,
                value: 'id',
                label: 'text',
            },
            roleTypeOptions: [],
        }
    },
    components: {},
    created() {},
    activated() {},
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.isShow = true
                this.resetForm('diaform')
                this.roleTypeOptions = []
                this.appId = ''
                this.diaform.roleId = ''
            }
        },
        'diaform.orgId'(val) {
            if (val && val.length && this.appId == 'pc_07'|| this.appId == 'pc_03') {
                this.getRoleType()
                this.diaform.roleTypeId = ''
            }
        },
    },
    methods: {
        // 打开对话框
        init(option, type) {
            this.type = type || 'add'
            this.dialogVisible = true
            this.appId = option.appId
            if (this.appId == 'pc_07' || this.appId == 'pc_03') {
                this.isShow = true
            } else {
                this.isShow = false
            }
            this.getOrgOption()
            if (this.type == 'update') {
                this.title = '编辑'
                this.$nextTick(() => {
                    let op = JSON.parse(JSON.stringify(option))
                    this.diaform.roleName = op.roleName
                    if (op.frontDisplay) {
                        console.log(op.frontDisplay)
                        let display = op.frontDisplay.split(',')
                        display.forEach((item, k) => {
                            display[k] = item
                        })
                        console.log(display)
                        this.diaform.orgId = display
                    } else {
                        this.diaform.orgId = op.orgId
                    }
                    this.diaform.roleExplain = op.roleExplain
                    // this.diaform.remark = op.remark
                    this.diaform.roleId = op.roleId
                    this.$nextTick(() => {
                        this.diaform.roleTypeId = op.roleTypeId
                    })
                })
            } else {
                this.title = '新增'
            }
        },
        // 重置
        resetForm(formName) {
            this.$nextTick(() => {
                this.$refs[formName].resetFields()
            })
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.diaform.validate(valid => {
                if (valid) {
                    let url = ''
                    if (this.type == 'add') {
                        url = 'app/info/addRoleManage'
                    } else {
                        url = '/app/info/modifyRoleManage'
                    }
                    let option = JSON.parse(JSON.stringify(this.diaform))
                    option.appId = this.appId
                    if (typeof option.orgId == 'object') {
                        option.frontDisplay = option.orgId.toString()
                        option.orgId = option.orgId[option.orgId.length - 1]
                    }
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        },
        getOrgOption() {
            let url = '/sys/userApp/queryOrgDistribution'
            let option = {
                appId: this.appId,
            }
            this.$http.post(url, option).then(res => {
                console.log(res)
                ++this.isResouceShow
                this.orgOptions = res.detail
            })
        },
        getRoleType() {
            let option = {
                appId: this.appId,
                type: '1',
            }
            option.orgId = this.diaform.orgId[this.diaform.orgId.length - 1]
            let url = '/app/info/queryRoleType'
            this.$http.post(url, option).then(res => {
                this.roleTypeOptions = res.detail
            })
        },
    },
}
</script>
<style lang="scss" scoped="adduser">
</style>
<style lang="scss">
</style>

