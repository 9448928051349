<template>
    <div class="application">
        <jy-query :model="formInline" ref="queryForm">
            <jy-query-item label="应用名称" prop="appName">
                <el-input v-model="formInline.appName" placeholder="输入名称"></el-input>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
                <el-button type="primary" @click="resetForm('queryForm')">重置</el-button>
                <el-row>
                    <el-button type="primary" @click="add">新增</el-button>
                    <!-- <el-button type="primary" @click="batchDelete">批量删除</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table :data="lists" @selection-change="batchchange">
            <jy-table-column type="index" width="60" label="序号"></jy-table-column>
            <jy-table-column type="selection" width="70"></jy-table-column>
            <jy-table-column label="应用编号" prop="appId" min-width="100"></jy-table-column>
            <jy-table-column label="应用名称" prop="cName" min-width="100"></jy-table-column>
            <jy-table-column label="应用地址" prop="appAddr" min-width="100"></jy-table-column>
            <jy-table-column label="应用类型" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.type | change}}
                </template>
            </jy-table-column>
            <jy-table-column label="用户数" prop="memberCount" min-width="100"></jy-table-column>
            <jy-table-column label="创建人" prop="createName" min-width="100"></jy-table-column>
            <jy-table-column label="创建时间" prop="createTime" min-width="150"></jy-table-column>
            <jy-operate :width="operateWidth" :list="operateList"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
        <add-dialog ref="dialog" @updateList="oncheck"></add-dialog>
        <userlist-dialog ref="userdialog" @updateList="oncheck"></userlist-dialog>
        <institutions-dialog ref="institutions" @updateList="oncheck"></institutions-dialog>
        <role-dialog ref="role" @updateList="oncheck"></role-dialog>
        <module-dialog ref="childmodule" @updateList="oncheck"></module-dialog>
        <user-dialog ref="user" @updateList="oncheck"></user-dialog>
    </div>
</template>
<script>
import adddialog from '@/components/application/addapplication'
import userList from '@/components/application/userList'
import institutionsDialog from '@/components/application/institutions'
import roleDialog from '@/components/application/role'
import moduleDialog from '@/components/application/childmodule'
import userDialog from '@/components/application/user'
export default {
    data() {
        return {
            formInline: {
                appName: '',
            },
            lists: [{}],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
            //  操作
            operateList: [],
            operateWidth: '420px',
            //批量操作
            batchDeletearr: [],
        }
    },
    created() {
        this.setOperateList()
        this.getlist()
    },
    components: {
        'add-dialog': adddialog,
        'userlist-dialog': userList,
        'institutions-dialog': institutionsDialog,
        'role-dialog': roleDialog,
        'module-dialog': moduleDialog,
        'user-dialog': userDialog,
    },
    filters: {
        change(val) {
            if (val == '0') {
                return 'PC应用'
            } else {
                return '移动应用'
            }
        },
    },
    methods: {
        setOperateList() {
            let l = [
                {
                    name: '编辑',
                    icon: 'el-icon-edit-outline',
                    fun: this.handleEdit,
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                },
                {
                    name: '机构管理',
                    icon: 'el-icon-delete',
                    fun: this.institutionsManagement,
                    isShow(row) {
                        if (row.appStatistics == '2') {
                            return true
                        } else {
                            return false
                        }
                    },
                },
                {
                    name: '角色管理',
                    icon: 'el-icon-delete',
                    fun: this.roleManagement,
                    isShow(row) {
                        if (row.appStatistics == '2') {
                            return true
                        } else {
                            return false
                        }
                    },
                },
                {
                    name: '用户管理',
                    icon: 'el-icon-delete',
                    fun: this.userManagement,
                    isShow(row) {
                        if (row.appStatistics == '2') {
                            return true
                        } else {
                            return false
                        }
                    },
                },
                {
                    name: '用户列表',
                    icon: 'el-icon-delete',
                    fun: this.uesrlist,
                    isShow(row) {
                        if (row.appStatistics == '1') {
                            return true
                        } else {
                            return false
                        }
                    },
                },
                {
                    name: '子模块',
                    icon: 'el-icon-delete',
                    fun: this.moduleManagement,
                    isShow(row) {
                        console.log(row)
                        if (row.appStatistics != '1') {
                            return true
                        } else {
                            return false
                        }
                    },
                },
            ]
            this.operateList = l
        },
        getlist() {
            let url = '/app/info/queryPage'
            let option = {
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            this.$http.post(url, option).then(res => {
                this.lists = res.detail.list
                this.total = res.detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 新增
        add() {
            this.$refs.dialog.init()
            console.log('新增')
        },

        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该应用?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let params = []
                    params.push(row.appId)
                    this.deleteRecord(params)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
            //   console.log(row)
        },
        //批量删除
        batchchange(val) {
            let arr = []
            val.forEach(item => {
                arr.push(item.appId)
            })
            this.batchDeletearr = arr
        },
        batchDelete() {
            if (this.batchDeletearr.length == 0) {
                this.$message({
                    message: '请先选择删除项',
                    type: 'warning',
                })
                return false
            }
            this.$confirm('是否删除选中应用?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.deleteRecord(this.batchDeletearr)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        deleteRecord(arr) {
            let url = '/app/info/delete'
            let option = arr
            this.$http.post(url, option).then(res => {
                this.$message({
                    message: res.msg,
                    type: 'success',
                })
                this.oncheck()
            })
        },
        // 修改
        handleEdit(row) {
            this.$refs.dialog.init(row, 'update')
            console.log('编辑')
        },

        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },
        //用户列表
        uesrlist(row) {
            this.$refs.userdialog.init(row)
        },
        //机构管理
        institutionsManagement(row) {
            this.$refs.institutions.init(row)
        },
        //角色管理
        roleManagement(row) {
            this.$refs.role.init(row)
        },
        //用户管理
        userManagement(row) {
            this.$refs.user.init(row)
        },
        //子模块
        moduleManagement(row) {
            this.$refs.childmodule.init(row)
        },
    },
}
</script>
<style lang="scss" scoped="application">
.application {
}
</style>


