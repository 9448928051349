<template>
    <div class="adduser">
        <jy-dialog :title="title" :visible.sync="dialogVisible">
            <div>
                <el-form :model="diaform" ref="diaform" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="子模块名称:" prop="service">
                        <el-input v-model="diaform.service"></el-input>
                    </el-form-item>
                    <el-form-item label="子模块图标:" prop="logoUrl">
                        <el-upload class="avatar-uploader" action="/supportCenter/oss/upload" :headers="headers" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                            <img v-if="diaform.logoUrl" :src="diaform.logoUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <div class="img_text">注：只能上传jpg/png文件，图片尺寸须50像素*50像素，且不超过50KB</div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 对话框类型
            appId: '',
            type: '',
            title: '',

            headers: {
                token: this.$util.getStor('token'),
            },
            diaform: {
                service: '',
                logoUrl: '',
                id: '',
            },
            dialogVisible: false,
            formLabelWidth: '100px',

            rules: {
                service: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur',
                    },
                ],
                logoUrl: [
                    {
                        required: true,
                        message: '请上传图标',
                        trigger: 'change',
                    },
                ],
            },
        }
    },
    components: {},
    created() {},
    activated() {},
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('diaform')
                this.diaform.id = ''
            }
        },
    },
    methods: {
        // 打开对话框
        init(option, type) {
            this.type = type || 'add'
            this.dialogVisible = true
            this.appId = option.appId
            if (this.type == 'update') {
                this.title = '编辑'
                this.$nextTick(() => {
                    this.diaform.service = option.service
                    this.diaform.logoUrl = option.logoUrl
                    this.diaform.id = option.id
                })
            } else {
                this.title = '新增'
            }
        },
        // 重置
        resetForm(formName) {
            this.$nextTick(() => {
                this.$refs[formName].resetFields()
            })
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.diaform.validate(valid => {
                if (valid) {
                    let url = ''
                    if (this.type == 'add') {
                        url = '/app/service/save'
                    } else {
                        url = '/app/service/modify'
                    }
                    let option = JSON.parse(JSON.stringify(this.diaform))
                    option.appId = this.appId

                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        },
        handleAvatarSuccess(res) {
            console.log(res)
            this.diaform.logoUrl = res.msg
            this.$refs.diaform.validateField('logoUrl')
        },
        beforeAvatarUpload(file) {
            console.log(file)
            const isJPGpng =
                file.type === 'image/jpeg' || file.type === 'image/png'
            // const isLt2M = file.size / 1024 / 1024 < 2

            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG 格式!')
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 2MB!')
            // }
            // return isJPG && isLt2M
            return isJPGpng
        },
    },
}
</script>
<style lang="scss" scoped="adduser">
.adduser {
    .img_text {
        padding-left: 100px;
        color: red;
        font-size: 14px;
    }
}
</style>
<style lang="scss">
</style>

