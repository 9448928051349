import { render, staticRenderFns } from "./addInstitutions.vue?vue&type=template&id=472745d7&scoped=true&"
import script from "./addInstitutions.vue?vue&type=script&lang=js&"
export * from "./addInstitutions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "472745d7",
  null
  
)

export default component.exports